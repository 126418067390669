import React, { useState, useEffect } from 'react';
import "./App.css";
import { useLocation } from 'react-router-dom';
import { getBasketValue, setBasketValue } from './BasketValue.js';

const BasketView = () => {
  const location = useLocation();
  const { state } = location;
  const value = getBasketValue();
  const [order, setOrder] = useState(value);
  const [products, setProducts] = useState(value.map(product => ({ name: product, quantity: Number(product.substring(product.indexOf('x') + 1)), price: Number(product.match(/\d+\.\d+/))})));
  const [shippingCost, setShippingCost] = useState(20.99);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleShippingChange = (e) => {
    const cost = e.target.value === "Kurier" ? 20.99 : 15.99;
    setShippingCost(cost);
  };

  async function Submit(e) {
    e.preventDefault();
    setIsSubmitting(true);
    const totalAmount = calculateTotalSum();

    if (totalAmount <= shippingCost) {
      alert('Nie można złożyć pustego zamówienia');
      setIsSubmitting(false);
      return;
    }

    const confirmation = window.confirm('Czy na pewno chcesz złożyć to zamówienie?');

    if (confirmation) {
      const formData = new FormData(e.target);
      const jsonFormat = {};

      order.forEach(item => {
        const [name, option, quantity] = item.match(/(.+?)\s+-\s(.+?)\s\/.+\sx(\d+)/).slice(1, 4);
        const key = `${name.trim()} - ${option.trim()}`.replace(/\\"/g, '');
        jsonFormat[key] = parseInt(quantity, 10);
      });

      formData.set('Order', JSON.stringify(jsonFormat));

      try {
        const response = await fetch('https://naturianki.pl/api/', {
          method: 'POST',
          body: JSON.stringify(Object.fromEntries(formData)),
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          const responseData = await response.json();
          if (responseData.success) {
            await fetch('https://naturianki.pl/api3/', {
              method: 'POST',
              body: JSON.stringify({ orderId: responseData.orderId }),
              headers: {
                'Content-Type': 'application/json',
              },
            })
            .then(response => {
              if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
              }
              return response.json();
            })
            .then(data => {
              if (data.success) {
                console.log('Email sent successfully');
              } else {
                console.error('Error in sending email:', data.message);
              }
            })
            .catch(error => {
              console.error('Error during fetch operation:', error.message);
              alert('An error occurred while sending the email: ' + error.message);
            });
            setOrder([]); 
            alert('"Zamówienie zostało wysłane. W przeciągu kilku minut otrzyma Pan/Pani wiadomość zwrotną na podany adres E-Mail. W przypadku nie otrzymania wiadomości prosimy o kontakt."')
            window.location = '/';
          } else {
            alert('Błąd podczas przesyłania zamówienia.');
          }
        } else {
          alert('Błąd podczas przesyłania zamówienia.');
        }
      } catch (error) {
        console.error('Błąd podczas przesyłania zamówienia:', error);
      }
    }

    setIsSubmitting(false);
    window.location.href = 'https://naturianki.pl';
  }

  useEffect(() => {
    setBasketValue(order);
  }, [order]);

  const handleQuantityChange = async (productName, action) => {
    let products2 = [];

    await fetch('https://naturianki.pl/api2/')
        .then(res => res.json())
        .then(data => {
          products2 = data['data'];
        });

    const searchName = productName.substring(0, productName.indexOf(' - '));
    const searchType = productName.substring(productName.indexOf(' - ')+3,productName.indexOf('/')-1);

    const el = products2.filter(x => {return x['nazwa'] === searchName && x['opakowanie'].trim() === searchType})

    const el2 = products.filter(x => {return x['name'].indexOf(searchName) >= 0 && x['name'].indexOf(searchType) >= 0})

    if(el2[0]['quantity'] >= el[0]['ilosc'] && action === 'increment'){
      alert('Brak większej ilości produktu');
      return;
    }

    setProducts(prevProducts => {
      const updatedProducts = prevProducts.map(product => {
        if (product.name === productName) {
          let price = Number(product.name.match(/\d+\.\d+/));
          if (action === 'increment') {
            return { ...product, quantity: product.quantity + 1, price: price * (product.quantity + 1) };
          } else if (action === 'decrement' && product.quantity > 0) {
            return { ...product, quantity: product.quantity - 1, price: price * (product.quantity - 1) };
          }
        }
        return product;
      });

      const filteredProducts = updatedProducts.filter(product => product.quantity > 0);

      const updatedOrder = filteredProducts.map((product, i) => `${i + 1}. ${product.name.slice(0, -2)} x${product.quantity} \n`);
      setOrder(updatedOrder);

      const updatedValue = filteredProducts.map(product => `${product.name.slice(0, -2)} x${product.quantity}`);
      setOrder(updatedValue);
      return filteredProducts;
    });
  };

  const now = new Date();
  const dzien = now.getDate();
  const miesiac = now.getMonth() + 1;
  const rok = now.getFullYear();

  const calculateTotalSum = () => {
    return (products.reduce((total, product) => total + product.price, 0) + shippingCost).toFixed(2);
  };

  return (
    <div className={'basketViewContainer'}>
      <h2>Koszyk</h2>
      <div className={'basketListContainer'}>
        <React.Fragment>
          {products.map((product, index) => (
            <div key={index} className={'basketListElement'}>
              <span className={'basketListElementName'}>{'- ' + product.name.slice(0, -2).replace('x', '')}</span>
              <div className={'basketQtyControl'}>
                <button onClick={() => handleQuantityChange(product.name, 'decrement')}>-</button>
                <span className={'basketListQty'}>{product.quantity}</span>
                <button onClick={() => handleQuantityChange(product.name, 'increment')}>+</button>
              </div>
              <span className={'basketPrice'}>{product.price  + ' zł'}</span>
              <br />
            </div>
          ))}
        </React.Fragment>
      </div>
      <form style={{ display: 'block' }} className="form" onSubmit={(e) => Submit(e)}>
        <input placeholder="Data" name="DateVar" id="DateVar" type="text" required="required" value={dzien + '/' + miesiac + '/' + rok} style={{ display: 'none' }} readOnly/>
        <input placeholder="Imię" name="Imie" id="Imie" type="text" required="required"/>
        <input placeholder="Nazwisko" name="Nazwisko" id="Nazwisko" type="text" required="required"/>
         <textarea placeholder="Pełen adres do wybranej opcji wysyłki. Miejscowość, nr domu/mieszkania. Kod pocztowy. W przypadku paczkomatu prosimy o podanie adresu oraz nr paczkomatu " name="Adres" id="Adres" type="text" required="required"/>
         <input placeholder="Telefon" name="Telefon" id="Telefon" type="number" required="required"/>
        <input placeholder="Email" name="Email" id="Email" type="email" required="required"/>
        <label style={{ textAlign: 'center', width: '100%' }}>Sposób dostawy</label>
        <select name="SposobDostawy" id="SposobDostawy" required="required" onChange={handleShippingChange}>
          <option value="Kurier">Kurier 20.99 zł</option>
          <option value="Paczkomat">Paczkomat InPost 15.99 zł</option>
        </select>
        <input style={{ display: 'none' }} name="Order" id="Order" type="text" value={order} required="required" readOnly/>
        <input placeholder="Kwota" name="Kwota" id="Kwota" type="text" value={calculateTotalSum()} style={{ display: 'none' }} required="required" readOnly/>
        <div className={'totalSum'}>
          <p style={{ fontSize: '1.7rem' }}>Suma: {calculateTotalSum()} PLN</p>
        </div>
        <input name="Name" type="submit" className={'submit'} disabled={isSubmitting} />
      </form>
    </div>
  );
};

export default BasketView;
